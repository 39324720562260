<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      @keydown.native.enter.exact.prevent.stop
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        prop="hangup_header_node.data.headers_json"
        :label="__('Headers')"
      >
        <query-params
          v-model="queryParams"
          :use-default-field="false"
          :use-default-key-field="false"
          :use-secure-variables="false"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import QueryParams from "../components/QueryParams";
import _ from "lodash";
import {
  filterRowsIfEveryKeyValueIsAbsent,
  filterRowsIfSomeKeyValueIsAbsent
} from "@/utils/collection";

export default {
  components: {
    QueryParams
  },
  mixins: [BaseNode],
  data() {
    const checkIfAtLeastOneHeaderJSONIsPresent = (rule, value, cb) => {
      if (_.isEmpty(filterRowsIfEveryKeyValueIsAbsent(value))) {
        cb(__("Headers Json is required"));
      } else {
        cb();
      }
    };
    return {
      rules: {
        hangup_header_node: {
          data: {
            headers_json: [
              {
                required: true,
                message: __("Headers Json is required"),
                trigger: "blur"
              },
              {
                validator: checkIfAtLeastOneHeaderJSONIsPresent,
                trigger: "blur"
              }
            ]
          }
        }
      }
    };
  },
  computed: {
    queryParams: {
      get: function() {
        return this.stringifyJsonArray(
          this.nodeToBind.hangup_header_node.data.headers_json
        );
      },
      set: function(val) {
        this.nodeToBind.hangup_header_node.data.headers_json = val;
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.hangup_header_node)
    ) {
      this.$set(this.nodeToBind, "hangup_header_node", {});
      this.$set(this.nodeToBind.hangup_header_node, "data", {});
      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.HANGUP_HEADER.NODE_TYPE
      );
    }
    this.$set(
      this.nodeToBind.hangup_header_node.data,
      "headers_json",
      this.queryParams
    );
  },
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      let nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      // cleanup query_params_json
      nodeToCleanUp.hangup_header_node.data.headers_json = filterRowsIfSomeKeyValueIsAbsent(
        JSON.parse(nodeToCleanUp.hangup_header_node.data.headers_json),
        "key"
      );
      return nodeToCleanUp;
    },
    cleanUpNode() {
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/node_common.scss";
</style>
